import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Freedom Wheels Rentals
			</title>
			<meta name={"description"} content={"Исследуйте. Прокатитесь. Наслаждайтесь."} />
			<meta property={"og:title"} content={"Главная | Freedom Wheels Rentals"} />
			<meta property={"og:description"} content={"Исследуйте. Прокатитесь. Наслаждайтесь."} />
			<meta property={"og:image"} content={"https://zestfulwanderer.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://zestfulwanderer.com/img/436257475.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://zestfulwanderer.com/img/436257475.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://zestfulwanderer.com/img/436257475.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://zestfulwanderer.com/img/436257475.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://zestfulwanderer.com/img/436257475.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://zestfulwanderer.com/img/436257475.jpeg"} />
			<meta name={"msapplication-TileColor"} content={"https://zestfulwanderer.com/img/436257475.jpeg"} />
		</Helmet>
		<Components.TukazaeHeader />
		<Section padding="112px 0 112px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.5) 0%,rgba(0, 0, 0, 0.7) 100%),rgba(0, 0, 0, 0) url(https://zestfulwanderer.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="96px 0 96px 0" sm-padding="72px 0 72px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Freedom Wheels Rentals
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Добро пожаловать в ваше следующее приключение на двух колесах! В компании Freedom Wheels Rentals мы специализируемся на предоставлении высококачественных мотоциклов, которые гарантируют захватывающее путешествие, независимо от того, едете ли вы по живописным маршрутам или ориентируетесь в городских условиях. Мы стремимся к тому, чтобы каждая поездка была незабываемой, а удовлетворенность клиентов была в центре нашей работы.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact-us"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Связаться с нами
					</Link>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 80px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 50px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					О нас
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--lead" lg-text-align="center">
				Компания Freedom Wheels Rentals - это маяк для любителей велосипедов и обычных велосипедистов. Мы предлагаем разнообразный парк мотоциклов, от проворных скутеров, идеально подходящих для городского движения, до мощных круизеров, предназначенных для открытых дорог. Наша команда страстно любит мотоциклы и стремится поддерживать каждый из них в первозданном состоянии, чтобы ваша поездка всегда была плавной и безопасной.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="0px 0px 0px 32px"
				lg-width="100%"
				display="flex"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(3, 180px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="32px"
					max-width="600px"
					md-grid-template-rows="repeat(3, 25vw)"
					sm-grid-gap="16px"
				>
					<Image
						src="https://zestfulwanderer.com/img/2.jpg"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="1 / span 1"
						grid-row="1 / span 2"
					/>
					<Image
						src="https://zestfulwanderer.com/img/3.jpg"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="2 / span 1"
						grid-row="2 / span 2"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" align-content="flex-start">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 16px 0px" font="--headline1" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Почему выбирают нас?
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="start"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Надежность
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						Каждый велосипед проходит тщательную проверку и обслуживание, чтобы соответствовать самым высоким стандартам производительности и безопасности.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					position="relative"
				>
					
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Разнообразие
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						От скутеров до спортивных мотоциклов - в нашем автопарке найдется место для всех типов велосипедистов и предпочтений.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Гибкая аренда
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						Мы предлагаем как краткосрочные, так и долгосрочные варианты аренды, чтобы соответствовать вашему графику.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" align-content="flex-start" id="menu">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="start"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://zestfulwanderer.com/img/4.jpg"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Спортивные мотоциклы
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						Для тех, кто жаждет скорости и производительности, наши спортивные мотоциклы проходят тщательное техническое обслуживание, обеспечивая высочайшую эффективность и захватывающие поездки.
						<br/>
Высокая производительность: Наслаждайтесь новейшими моделями, оснащенными передовыми технологиями для превосходной езды.
						<br/>
Защитное снаряжение в комплекте: Шлемы, перчатки и куртки для вашей безопасности и комфорта.

						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					position="relative"
				>
					<Image
						src="https://zestfulwanderer.com/img/5.jpg"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Круизеры
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						Наши круизеры идеально подходят для долгих неспешных поездок, обеспечивая комфорт и стиль.
						<br/>
Удобное сиденье: Благодаря эргономичным сиденьям вы сможете с легкостью совершать длительные поездки.
						<br/>
Возможности хранения: Оснащен достаточным количеством мест для хранения необходимых вещей.

						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="0px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://zestfulwanderer.com/img/6.jpg"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						height="278px"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Скутеры
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						Идеально подходят для поездок по городу и быстрых путешествий, наши скутеры маневренны и экономичны.
						<br/>
Легко ездить: Легкий и удобный, идеально подходит для новичков.
						<br/>
Экономичный: Отличный выбор для экономных райдеров.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="112px 0 0px 0" background="--color-darkL2" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 40px 0px"
				padding="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Узнайте больше
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					width="80%"
				>
					Вам интересно узнать обо всем, что мы предлагаем? Посетите нас, чтобы ознакомиться с полным спектром наших услуг и узнать, как мы можем сделать вашу следующую поездку незабываемой. Планируете ли вы одиночное приключение или групповую экскурсию, компания Freedom Wheels Rentals станет вашим надежным спутником в дороге.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s linear 0s"
					hover-background="--color-orange"
					transition="background-color 0.2s linear 0s"
				>
					Контакты
				</Link>
			</Box>
		</Section>
		<Components.TukazaeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});